import React from 'react'
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/careers-detail.css'
import { StaticImage } from "gatsby-plugin-image"
import {Link, navigate} from 'gatsby'

import Linkedin from "../images/linkedIn.png";
import Twitter from "../images/twitter.png";
import Facebook from "../images/facebook.png";
import Whatsapp from "../images/whatsapp.png";


class CareersDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        jobId : '',
        jobPosition: '',
        jobDepartment: '',
        jobLocation: '',
        jobDescription: [],
        jobRequirement: [],
      }
    }

    shareJob(title, media){
      var id = this.state.jobId
      var job = "https://neurafarm.com/careers-detail?" + id
      var url = ""
      if (media=="Whatsapp"){
        url = "whatsapp://send?text=" + title + " Job - Neurafarm Career%0A" + encodeURIComponent("https://neurafarm.com/careers-detail?") + id
      } else if (media=="Linkedin"){
        url = "https://www.linkedin.com/shareArticle?mini=true&url=" + job
      } else if (media=="Facebook"){
        url = "https://www.facebook.com/sharer/sharer.php?u=" + job
      } else if (media=="Twitter"){
        url = "https://twitter.com/intent/tweet?text=" + title + " Job - Neurafarm Career%0A" + job
      }
      window.open(url)
    }

    componentDidMount(){
      if (this.props.location.state!=null) {
        this.setState({jobId: this.props.location.state.jobId})
        this.setState({jobPosition: this.props.location.state.jobPosition})
        this.setState({jobDepartment: this.props.location.state.jobdepartment})
        this.setState({jobLocation: this.props.location.state.jobLocation})
        this.setState({jobDescription: this.props.location.state.jobDescription})
        this.setState({jobRequirement: this.props.location.state.jobRequirement})
      } else{
        console.log(this.props.location.search)
        if (this.props.location.search != ''){
          var id = this.props.location.search.split('?')
          this.setState({jobId: id[1]})
          fetch("https://tania-cms.azurewebsites.net/jobs")
          .then(response => response.json())
          .then(data => {
            for(var idx in data){
              if(data[idx]["id"] == this.state.jobId){
                var descTemp = (data[idx]["job_description"].split('- ')).filter((data) => data != "")
                var reqTemp = (data[idx]["job_requirements"].split('- ')).filter((data) => data != "")
                this.setState({jobPosition: data[idx]["job_title"]})
                this.setState({jobDepartment: data[idx]["department"]["department_name"]})
                this.setState({jobLocation: data[idx]["location"]["location_name"]})
                this.setState({jobDescription: descTemp})
                this.setState({jobRequirement: reqTemp})
              }
            }
          })
        } else{
          navigate('/careers')
        }
      }
    }

    render(){

        return(

            <Layout>
                <Head title = "Careers"/>
                <div id = "car-main-container">
                    
                    <div id = "car-det-title">

                      <div id = "car-det-title-container">

                        <div id = "car-det-title-primary">Jobs</div>
                        <div id = "car-det-title-jobs">
                          <div id = "car-det-title-jobs-txt">{this.state.jobDepartment}</div>
                          <div id = "car-det-title-jobs-arrow">{">"}</div> 
                          <div id = "car-det-title-jobs-txt">{this.state.jobPosition}</div>
                        </div>
                      
                      </div>

                    </div>

                    <div id = "car-det-body">

                        <div id = "car-det-content">        

                          <div id = "det-position">{this.state.jobPosition}</div>
                          
                          <div id = "det-info">
                            <div>
                              <img class = "item-icon" src = "https://i.ibb.co/RbCN9Jv/work-2.png" alt = "department-img"/>
                              <span class = "item-info">{this.state.jobDepartment}</span>
                            </div>
                            <div>
                              <img class = "item-icon" src = "https://i.ibb.co/3dH5FsW/pin-2.png" alt = "location-img"/>
                              <span class = "item-info">{this.state.jobLocation}</span>
                            </div>
                          </div>

                          <div id = "det-job-desc">
                            
                            <div class = "det-info-title">Job Description</div>

                            <ul class = "car-det-list">
                            {this.state.jobDescription.map((desc) => (
                              <li class = "det-info-list">{desc}</li>
                            ))}
                            </ul>

                          </div>

                          <div id = "det-job-req">
                            <div class = "det-info-title">Job Requirements</div>
                          </div>

                          <ul class = "car-det-list">
                            {this.state.jobRequirement.map((desc) => (
                              <li class = "det-info-list">{desc}</li>
                            ))}
                          </ul>

                        </div>

                        <div id = "car-det-sidebar">
                          
                          <div id = "car-det-sidebar-container">
                          
                          <div className = "sidebar-apply-btn">
                            <Link
                              className = "sidebar-apply-txt"
                              to={"/apply-job"}
                              state={{
                                jobId : this.state.jobId,
                                jobPosition: this.state.jobPosition,
                                jobdepartment: this.state.jobDepartment,
                                jobLocation: this.state.jobLocation
                              }}
                            >
                              Apply
                            </Link>
                          </div>
                          
                          <div id = "sidebar-msg">
                            Not what you had in mind? <br/>
                            <Link className = "car-det-link-container" to = "/careers" state = {{jobdepartment: this.state.jobDepartment}}>Find related jobs.</Link>
                          </div>

                          <br/><br/><br/>
                          
                          <div class = "sidebar-link">Share</div>
                          <div id = "sidebar-share">
                            <StaticImage class = "sidebar-icon" src = {Linkedin} alt = "Share on Linkedin" onClick={() => this.shareJob(this.state.jobPosition, 'Linkedin')}/>
                            <StaticImage class = "sidebar-icon" src = {Twitter} alt = "Share on Twitter" onClick={() => this.shareJob(this.state.jobPosition, 'Twitter')}/>
                            <StaticImage class = "sidebar-icon" src = {Facebook} alt = "Share on Facebook" onClick={() => this.shareJob(this.state.jobPosition, 'Facebook')}/>
                            <StaticImage class = "sidebar-icon" src = {Whatsapp} alt = "Share on Whatsapp" onClick={() => this.shareJob(this.state.jobPosition, 'Whatsapp')}/>
                          </div>

                          </div>

                        </div>

                    </div>

                </div>
            </Layout>
        
        );
    }
}

export default CareersDetail;
